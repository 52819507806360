import { styled } from 'styled-components';

import {
  formSubmittingDisplayBlockIfSubmittingVar,
  formSubmittingOneIfSubmittingVar,
} from 'components/Form/formSubmittingConstants';

export const SubmitButtonChildrenHolder = styled.div`
  opacity: calc(var(${formSubmittingOneIfSubmittingVar}, 2) - 1);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const SubmitButtonLoadingDotsHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: var(${formSubmittingDisplayBlockIfSubmittingVar}, none);
`;

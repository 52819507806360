import type { ChangeEvent, FocusEvent, KeyboardEvent, ReactNode } from 'react';

import { Textarea } from 'components/Textarea/Textarea';

import { CharacterCounter } from './CharacterCounter';
import { Field } from './Field/Field';

type Props = {
  description?: string | ReactNode;
  noMargin?: boolean;
  label?: string;
  maxLength?: number;
  name: string;
  onUpdateValue?: (arg0: string) => void;
  placeholder?: string;
  rows?: number;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
};

export function TextAreaField({
  name,
  noMargin,
  label,
  description,
  placeholder,
  maxLength,
  onUpdateValue,
  onBlur,
  ...props
}: Props) {
  return (
    <Field
      inputProps={props}
      name={name}
      label={label}
      noMargin={noMargin}
      description={description}
      renderInput={({ value, id, qaId, hasError, updateValue }) => (
        <>
          <Textarea
            id={id}
            name={name}
            value={value || ''}
            $hasError={hasError}
            data-qa-id={qaId}
            maxLength={maxLength}
            onChange={(e) => {
              updateValue(e.target.value);

              if (onUpdateValue) {
                onUpdateValue(e.target.value);
              }
            }}
            onBlur={onBlur}
            placeholder={placeholder}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />

          {maxLength && (
            <CharacterCounter
              maxLength={maxLength}
              currentCount={(value || '').length}
            />
          )}
        </>
      )}
    />
  );
}

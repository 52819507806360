import {
  FeedbackModalActions,
  FeedbackModalIcon,
} from 'components/FeedbackModal/FeedbackModal.styled';
import { Form } from 'components/Form/Form';
import {
  type FormContextValue,
  type FormSubmitFunction,
} from 'components/Form/FormContext';
import { SubmitButton } from 'components/Form/SubmitButton/SubmitButton';
import { TextAreaField } from 'components/Form/TextAreaField';
import { Modal } from 'modals/Modal';
import { getStaticImageSrc } from 'utils/getImageSrc';

type FeedbackModalProps = {
  isPositive: boolean;
  onSubmit: (comment: string) => Promise<void>;
  onClose: (hasSubmittedFeedback: boolean) => void;
  titlePositive: string;
  titleNegative: string;
  analyticsTitle: string;
  qaId: string;
};

type FeedbackFormValues = {
  comment: string;
};

export function FeedbackModal({
  isPositive,
  onSubmit,
  onClose,
  titlePositive,
  titleNegative,
  analyticsTitle,
  qaId,
}: FeedbackModalProps) {
  const handleSubmit: FormSubmitFunction<FeedbackFormValues> = async ({
    values,
  }) => {
    await onSubmit(values.comment);
    onClose(true);
  };

  return (
    <Modal
      title={isPositive ? titlePositive : titleNegative}
      onClose={() => onClose(false)}
      data-qa-id={qaId}
      image={
        <FeedbackModalIcon
          src={getStaticImageSrc('feedback-modal-icon-lightbulb.svg')}
          alt=""
        />
      }
    >
      <Form<FeedbackFormValues, FormContextValue<FeedbackFormValues>>
        analyticsTitle={analyticsTitle}
        usageContext="modal"
        data-qa-id={`${qaId}-form`}
        onSubmit={handleSubmit}
        render={() => (
          <>
            <TextAreaField
              name="comment"
              rows={6}
              placeholder={getText('Enter text here…')}
            />
            <FeedbackModalActions>
              <SubmitButton>Submit</SubmitButton>
            </FeedbackModalActions>
          </>
        )}
      />
    </Modal>
  );
}

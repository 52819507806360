import { FocusTrap } from 'focus-trap-react';
import type { ReactNode } from 'react';
import { useRef } from 'react';
import { styled } from 'styled-components';

import { LegacyModalCloseButton } from 'components/LegacyButton/LegacyCloseButton';
import { useOnWindowKeyDown } from 'hooks/useOnWindowKeyDown';
import { colors } from 'theme/theme';

import type { ModalDismissalReason } from './ModalDismissalReason';

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9999;
  opacity: 0.85;
  background-color: ${colors.mediumContentGrey};
`;

const Content = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-start;
  inset: 0;
  z-index: 10000;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  padding: 16px;
  cursor: pointer;

  &::after {
    vertical-align: middle;
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: '';
  }
`;

export const Dialog = styled.div`
  position: relative;
  outline: 0;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 40%);
  display: block;
  text-align: left;
  box-sizing: border-box;
  max-width: 520px;
  margin: auto;
  cursor: default;
  color: ${colors.mediumContentGrey};
  line-height: 1.5;
`;

// Prevent clicks from going through to the backdrop
Dialog.defaultProps = {
  onClick: (event) => event.stopPropagation(),
};

export const CloseButton = styled(LegacyModalCloseButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
`;

type Props = {
  className?: string | undefined;
  onClose?: (reason: ModalDismissalReason) => void;
  children: ReactNode;
  'aria-labelledby'?: string;
};

export function ModalBase({
  className,
  onClose,
  children,
  'aria-labelledby': ariaLabeledBy,
}: Props) {
  const lastClosedAt = useRef<number>(0);
  const throttleDuration = 1000;

  // useOnWindowKeyDown is not throttled which means that multiple keydown
  // events will be fired on a single key press
  const onKeyboardClose = (reason: ModalDismissalReason) => {
    const now = Date.now();
    if (now - lastClosedAt.current < throttleDuration) return;
    lastClosedAt.current = now;
    onClose?.(reason);
  };

  useOnWindowKeyDown(['Escape'], (e) =>
    onKeyboardClose({ type: 'press-escape', originalEvent: e }),
  );

  return (
    <>
      <Overlay />

      <FocusTrap
        focusTrapOptions={{
          tabbableOptions: { displayCheck: 'none' },
        }}
      >
        <Content
          className={className}
          role="alertdialog"
          aria-modal="true"
          aria-labelledby={ariaLabeledBy}
          id="idealist-modal-container"
        >
          {children}
        </Content>
      </FocusTrap>
    </>
  );
}
